import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const images = [
    "https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_700,f_auto/v1670406478/afyabook%20images/Afyabook_Online_Pharmacy_zsstz8.jpg",
    "https://res.cloudinary.com/tripleaim-software/image/upload/v1723038335/bunners/pexels-kampus-7551652_wnwa1m.jpg",
    "https://images.unsplash.com/photo-1516574187841-cb9cc2ca948b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGhlYWx0aHxlbnwwfHwwfHx8MA%3D%3D"
];

const SliderContainer = styled.div`
    width: 100%;
    max-width: 100%; /* Ensure it doesn't exceed the container width */
    margin: 0 auto;
    overflow: hidden; /* Prevent overflow */
`;

const StyledSlider = styled(Slider)`
    .slick-slide img {
        display: block;
        width: 100%;
        height: 350px;
        object-fit: cover; /* Ensures the image covers the area without distortion */
    }
`;

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, 
};

const ImageSlider = () => {
    return (
        <SliderContainer>
            <StyledSlider {...settings}>
                {images.map((src, index) => (
                    <div key={index}>
                        <img src={src} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </StyledSlider>
        </SliderContainer>
    );
};

export default ImageSlider;
