import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { graphql, useStaticQuery } from "gatsby"
import Next from "../../images/right-arrow.svg"
import Prev from "../../images/left-arrow.svg"
import AfyaLogo from "../../images/store.png"

const BrandCardContainer = styled.div`
  margin: 30px;
`
const BrandInnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`
const StyledSlider = styled(Slider)`
  &.slick-slide {
    outline: none;
    width: 285px;
    height: 220px;
  }
`
const CardContainer = styled.div`
  margin: 10px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);

  :hover {
    box-shadow: 0 1px 2px 1px #a3d4cb;
    border-radius: 8px;
  }
`
const CardWrapper = styled(Link)`
  padding: 10px;
  overflow: hidden;
`
const ImgContainer = styled.div`
  display: block;
  max-width: 120px;
  height: 120px;
  margin: 10px auto;
  position: relative;
`
const BrandLogo = styled.img`
  height: 100px;
  margin: auto;
  display: block;
  margin-top: 3rem;
  width: 100px;
`
const BrandName = styled.span`
  display: -webkit-inline-box;
  color: #212121;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
`

export default function Brands() {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Prev}
      alt="prevArrow"
      {...props}
      style={{ height: "40px", width: "40px" }}
    />
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Next}
      alt="nextArrow"
      {...props}
      style={{ height: "40px", width: "40px" }}
    />
  )
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: "progressive",
    slidesToShow: 7,
    slidesToScroll: 7,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const shopsData = useStaticQuery(graphql`
    query ShopQuery {
      allShops(limit: 10) {
        edges {
          node {
            name
            physicalAddress {
              county
              street
            }
            showShop
            licenseStatus
          }
        }
      }
    }
  `)

  const shops = shopsData.allShops.edges.map(item => {
    return item.node
  })

  return (
    <BrandCardContainer>
      <BrandInnerContainer>
        <StyledSlider {...settings}>
          {shops.map((item, index) => {
            const { physicalAddress, name, showShop } = item
            const { street, county } = physicalAddress
            return (
              showShop && (
                <div key={index}>
                  <CardContainer key={name}>
                    <CardWrapper
                      to={`/pharmacy/${name.replace(/ /g, "-").toLowerCase()}`}
                    >
                      <ImgContainer>
                        <div>
                          {/* change the picture */}
                          <BrandLogo src={AfyaLogo} alt="BrandLogo" />
                        </div>
                      </ImgContainer>
                      <BrandName>
                        {name.charAt(0).toUpperCase() +
                          name.slice(1).toLowerCase()}
                      </BrandName>
                      <div>
                        {street.charAt(0).toUpperCase() +
                          street.slice(1).toLowerCase()}
                      </div>
                      <div>
                        {county.charAt(0).toUpperCase() +
                          county.slice(1).toLowerCase()}
                      </div>
                    </CardWrapper>
                  </CardContainer>
                </div>
              )
            )
          })}
        </StyledSlider>
      </BrandInnerContainer>
    </BrandCardContainer>
  )
}
