import React from 'react'
import '../style/pharm.css'
import styled from 'styled-components'
import PageNav from '../components/homeNav/PageNavService'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Next from '../images/right-arrow.svg'
import Prev from '../images/left-arrow.svg'
import Med from '../components/med/ServiceCard'
import Brands from '../components/brands/Brands'
import Footer from '../components/homeNav/footer/Footer'
import Seo from '../components/seo'
import Logo from '../images/Afyabook.svg'
import BotNav from '../components/bottomNav'
import ImageSlider from '../components/ImageSlider';
import ImageSliderMobile from '../components/ImageSliderMobile';


const ProductSlideWrapper = styled.div`
  background: white;
  padding: 1em 1.5em;
  margin: 2em 0;
  width: 97%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 9%);
`
const BrandContainer = styled.div`
  margin: 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const BrandSlideWrapper = styled.div`
  display: inline-block;
  width: 100%;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.14);
  min-height: 150px;
`
const TopButton = styled(Link)`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border-radius: 8px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 15px;
  outline: 2.5px solid #1d9682;
  border: none;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2sease-in-out;
    background: #2e4158;
    color: #e9ecef;
    outline: 1px solid #2e4158;
  }
`
const ImageDiv = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    height: 300px;
    width: 700px;
  }
`
const ImageDivM = styled.div`
  display: block;
  height: 200px;
  width: 350px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const CategoryCard = styled.div`
  margin: 1rem;
  padding: 0.5rem;
  background: white;
  min-width: 200px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid gray;
  cursor: pointer;
`

const ImageDivBody = styled.div`
  width: 400px;
  height: 200px;

  @media screen and (min-width: 768px) {
    height: 220px;
  }
`

const ScrollBar = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: blue;
  align-items: center;
  justify-content: start;
  overflow: auto;
  margin-top: 10px;

  // &::-webkit-scrollbar {
  //   width: 0;
  // }

  @media (min-width: 768px) {
    margin-top: 80px; 
  }
`

export default function Pharm({ location }) {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Prev}
      alt="prevArrow"
      {...props}
      style={{ height: '40px', width: '40px' }}
    />
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Next}
      alt="nextArrow"
      {...props}
      style={{ height: '40px', width: '40px' }}
    />
  )
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: 'anticipated',
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  const data2 = useStaticQuery(graphql`
    query ServiceQuery {
      allServices {
        edges {
          node {
            id
            name
            description
            serviceType
            price
            showPrice
            createdAt
            updatedAt
            tags
            category
            image
            discount
            shop {
              name
              _id
              physicalAddress {
                county
                street
              }
            }
          }
        }
      }
    }
  `)

  const servicesR = data2.allServices.edges.map(item => {
    return item.node
  })

  //const products = productsR.filter((product)=>product.shop.name.toLowerCase()==="pharmilymed")

  const preferredShop = process.env.GATSBY_PREFERRED_SHOP

  const services = servicesR?.sort((a, b) => {
    if (a.shop._id === preferredShop) return -1
    if (b.shop._id === preferredShop) return 1
    return 0
  })

  const isBrowser = typeof window !== 'undefined'

  const goDown = e => {
    const anchor = isBrowser && window.document.querySelector('.mri')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownP = e => {
    const anchor =
      isBrowser && window.document.querySelector('.labs')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownV = e => {
    const anchor = isBrowser && window.document.querySelector('.ctscan')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const goDownU = e => {
    const anchor = isBrowser && window.document.querySelector('.us')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const goDownB = e => {
    const anchor = isBrowser && window.document.querySelector('.xray')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownS = e => {
    const anchor = isBrowser && window.document.querySelector('.sexual')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownC = e => {
    const anchor = isBrowser && window.document.querySelector('.contraceptives')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownD = e => {
    const anchor = isBrowser && window.document.querySelector('.devices')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownPharm = e => {
    const anchor = isBrowser && window.document.querySelector('#pharm')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <>
      <Seo
        title="Book for laboratory, diagnostic or imaging tests online | Afyabook"
        description="Book for laboratory, diagnostic or imaging tests online and have your samples collected from your home or office. Get your results delivered to you electronically. Afyabook is a platform that connects you to the best healthcare providers in Kenya"
        img={Logo}
        keywords="Laboratory services in kenya,Diagnostic services in kenya, Radiology services in kenya, Imaging services in kenya, csf tests in kenya,blood tests in kenya, stool tests in kenya, urinalysis, biochemistry, ct scan in kenya, x ray in kenya, x-ray in kenya, ultrasound, mri in kenya"
        siteUrl="https://www.afyabook.com/services"
        canonical="https://www.afyabook.com/services"
      ></Seo>
      <PageNav />
      <div className="main-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <ImageDiv>
            <ImageSlider />
          </ImageDiv>
          <ImageDivM>
            <ImageSliderMobile />
          </ImageDivM>
        </div>
        <ScrollBar>
          <CategoryCard onClick={goDown}>Offers</CategoryCard>
          <CategoryCard onClick={goDownU}>Ultrasound</CategoryCard>
          <CategoryCard onClick={goDownP}>Labs</CategoryCard>
          <CategoryCard onClick={goDown}>MRI</CategoryCard>
          <CategoryCard onClick={goDownV}>CT SCAN</CategoryCard>
          <CategoryCard onClick={goDownB}>X RAY</CategoryCard>
          {/* <CategoryCard onClick={goDownS}>Sexual Health</CategoryCard>
          <CategoryCard onClick={goDownC}>Family Planning</CategoryCard>
          <CategoryCard onClick={goDownD}>Medical devices</CategoryCard>
          <CategoryCard onClick={goDownPharm}>Pharmacies</CategoryCard> */}
        </ScrollBar>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Offers</span>
            </div>
            <span className="seeall">
              {/* <TopButton to="/offers">More</TopButton> */}
            </span>
          </div>
          <ProductSlideWrapper>
            <Slider {...settings}>
              {services.map(service => {
                //.sort((a,b)=>a.productName.toLowerCase()<b.productName.toLowerCase()?-1:(a.productName.toLowerCase()>b.productName.toLowerCase()?1:0)).
                return (
                    <Med services={service} />
                  
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Ultrasound</span>
            </div>
            <span className="seeall">
              {/* <TopButton to="/hygiene">More</TopButton> */}
            </span>
          </div>
          <ProductSlideWrapper className="us">
            <Slider {...settings}>
              {services.map(service => {
                return (
                  service.category.includes('ultrasound') && (
                    <Med services={service} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">MRI</span>
            </div>
            <span className="seeall">
              {/* <TopButton to="/hygiene">More</TopButton> */}
            </span>
          </div>
          <ProductSlideWrapper className="mri">
            <Slider {...settings}>
              {services.map(service => {
                return (
                  service.category.includes('mri') && (
                    <Med services={service} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">CT SCAN</span>
            </div>
            <span className="seeall">
              {/* <TopButton to="/hygiene">More</TopButton> */}
            </span>
          </div>
          <ProductSlideWrapper className="ctscan">
            <Slider {...settings}>
              {services.map(service => {
                return (
                  service.category.includes('ct scan') && (
                    <Med services={service} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">X RAY</span>
            </div>
            <span className="seeall">
              {/* <TopButton to="/hygiene">More</TopButton> */}
            </span>
          </div>
          <ProductSlideWrapper className="xray">
            <Slider {...settings}>
              {services.map(service => {
                return (
                  service.category.includes('x-ray') && (
                    <Med services={service} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ImageDivBody>
            <img
              src="https://res.cloudinary.com/tripleaim-software/image/upload/v1667843387/afyabook%20images/suppliments_bunner_gbthz2.jpg"
              width="100%"
              height="100%"
            />
          </ImageDivBody>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginBottom: '10px',
            width: '100%',
          }}
        >
          <Link to="/userprofile">
            <div style={{ maxWidth: '500px', height: '100%', margin: '10px' }}>
              <img
                src="https://res.cloudinary.com/tripleaim-software/image/upload/v1682074038/Record_and_monitor_your_measurements_now_bjozsh.jpg"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </div>
          </Link>
          <Link to="/upload-prescription">
            <div style={{ maxWidth: '500px', height: '100%', margin: '10px' }}>
              <img
                src="https://res.cloudinary.com/tripleaim-software/image/upload/v1682064267/uploads_tondld.png"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </div>
          </Link>
        </div>

        {/* <div className="container-fluid" style={{ margin: "0", padding: "0" }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading" id="pharm">Pharmacies</span>
            </div>            
          </div>
          <BrandContainer>
            <BrandSlideWrapper>
              <Brands />
            </BrandSlideWrapper>
          </BrandContainer>
        </div> */}
      </div>
      <BotNav />
      <Footer />
    </>
  )
}
