import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const images = [
    "https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_320,f_auto/v1670406478/afyabook%20images/Afyabook_Online_Pharmacy_zsstz8.jpg",
    "https://images.unsplash.com/photo-1494597564530-871f2b93ac55?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8aGVhbHRofGVufDB8fDB8fHww",
    "https://images.unsplash.com/photo-1516574187841-cb9cc2ca948b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGhlYWx0aHxlbnwwfHwwfHx8MA%3D%3D"
];

const SliderContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    overflow: hidden; 
`;

const StyledSlider = styled(Slider)`
    .slick-slide img {
        display: block;
        width: 100%;
        height: 160px;
        object-fit: cover; 
    }

    .slick-dots {
        bottom: 10px; 
    }
`;

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
};

const ImageSliderMobile = () => {
    return (
        <SliderContainer>
            <StyledSlider {...settings}>
                {images.map((src, index) => (
                    <div key={index}>
                        <img src={src} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </StyledSlider>
        </SliderContainer>
    );
};

export default ImageSliderMobile;
